<template>
    <b-row>
        <b-col cols="12">
            <b-card>
                <filter-requirements ref="FilterRequirements" @update="totalRecords = $event"/>
            </b-card>
        </b-col>
        <b-col cols="12">
            <b-card title="">
                <b-overlay
                    :show="showLoading"
                    blur="0"
                    opacity=".75"
                    rounded="sm"
                    spinner-variant="primary"
                    variant="white"
                >
<!--                    <div class="ml-1 mb-2">
                        <create-requirement-button/>
                    </div>-->
                    <b-row>
                        <b-col cols="12" md="9"
                               class="d-flex align-items-center justify-content-start mb-1"
                        >
                            <label>Mostrar</label>
                            <v-select
                                v-model="pageLength"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                :options="['3','5','10','20', '50', '100', '200']"
                                :clearable="false"
                                class="per-page-selector d-inline-block mx-50"
                                @input="changeLength"
                            />
                            <create-requirement-button/>
                        </b-col>
                    </b-row>
                    <table-requirements ref="tableRequirements"/>
                    <div class="mx-2 mb-2">
                        <b-row>
                            <b-col
                                class="d-flex align-items-center justify-content-center justify-content-sm-start"
                                cols="12"
                                sm="6"
                            >
                                <span class="text-muted">Mostrant {{ pageLength }} de {{ totalRecords }} requeriments </span>
                            </b-col>

                            <b-col
                                class="d-flex align-items-center justify-content-center justify-content-sm-end"
                                cols="12"
                                sm="6"
                            >
                                <b-pagination
                                    :per-page="pageLength"
                                    :total-rows="totalRecords"
                                    :value="1"
                                    align="right"
                                    class="mt-1 mb-0"
                                    first-number
                                    last-number
                                    next-class="next-item"
                                    prev-class="prev-item"
                                    @input="changePage"
                                >
                                    <template #prev-text>
                                        <feather-icon
                                            icon="ChevronLeftIcon"
                                            size="18"
                                        />
                                    </template>
                                    <template #next-text>
                                        <feather-icon
                                            icon="ChevronRightIcon"
                                            size="18"
                                        />
                                    </template>
                                </b-pagination>
                            </b-col>
                        </b-row>
                    </div>
                </b-overlay>
            </b-card>
        </b-col>
    </b-row>
</template>

<script>
import {
    BRow,
    BCol,
    BTable,
    BCard,
    BBadge,
    BButton,
    BTooltip,
    BPagination,
    BFormInput,
    BOverlay,
    BFormGroup,
    BIcon,
    BInputGroupText,
} from 'bootstrap-vue'
import vSelect from "vue-select"
import TableRequirements from "@/views/accounting/requirements/list/components/tableRequirements"
import CreateRequirementButton from "@/views/accounting/requirements/list/components/createRequirementButton"
import {createHelpers} from "vuex-map-fields"
import {requirementsStatus} from "@/constants/accounting"
import {Catalan as CatalanLocale} from 'flatpickr/dist/l10n/cat'
import flatPickr from "vue-flatpickr-component"
import FilterRequirements from "@/views/accounting/requirements/list/components/filterRequirementsView"

const {mapFields} = createHelpers({
    getterType: 'requirements/getField',
    mutationType: 'requirements/updateField',
})

export default {
    name: "ListRequirements",
    components: {
        BRow,
        BCol,
        BTable,
        BCard,
        BBadge,
        BButton,
        BTooltip,
        BPagination,
        BFormInput,
        BOverlay,
        BFormGroup,
        BIcon,
        BInputGroupText,

        vSelect,
        flatPickr,

        TableRequirements,
        CreateRequirementButton,
        FilterRequirements

    },
    data() {
        return {
            page: 1,
            pageLength: 10,
            totalRecords: '',
            showLoading: false,

            requirementsStatus,
            CatalanLocale
        }
    },
    mounted() {
        this.$store.commit('requirements/RESET_STATE')
        this.$refs.FilterRequirements.getRequirements()
    },
    methods: {
        changePage(page) {
            this.page = page
            this.$refs.FilterRequirements.page = page
            this.$refs.FilterRequirements.getRequirements()
        },
        changeLength(length) {
            this.$refs.FilterRequirements.pageLength = length
            this.$refs.FilterRequirements.getRequirements()
        },

    }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.background-in_progress {
    color: #ffc107;
    border: 1px solid #ffc107;
}

.background-accepted {
    color: #28c76f;
    border: 1px solid #28c76f;
}

.background-sent_to_client {
    color: #00cfe8;
    border: 1px solid #00cfe8;
}

.background-closed {
    color: #82868b;
    border: 1px solid #82868b;
}

.background-denied {
    color: #ea5455;
    border: 1px solid #ea5455;
}

select option[value="in_progress"] {
    color: #ffc107 !important;
    background: rgba(255, 159, 67, 0.12);
}

select option[value="accepted"] {
    color: #28c76f !important;
    background: rgba(40, 199, 111, 0.12);
}

select option[value="sent_to_client"] {
    color: #00cfe8 !important;
    background: rgba(0, 207, 232, 0.12);
}

select option[value="closed"] {
    color: #82868b !important;
    background: rgba(130, 134, 139, 0.12);
}

select option[value="denied"] {
    color: #ea5455 !important;
    background: rgba(234, 84, 85, 0.12);
}
</style>